import { useRef, useEffect } from 'react'
//This hook updates the title of the page
function useTitle(title, prevailOnUnmount = false) {
  const defaultTitle = useRef(document.title);

  useEffect(() => {
    document.title = `${title} | Mark Cadavero, LCSW, PLLC`;
  }, [title]);

  useEffect(() => () => {
    if (!prevailOnUnmount) {
      document.title = defaultTitle.current;
    }
  }, [])
}

export default useTitle