import Star from "./Star"
export default function Stars (props) {
    const {curVal,setCurVal,name} = props
    return(
        <div style={{display: 'flex',alignItems: 'center',width: 150}}>
            <Star name={name} value={1} onClick={()=>setCurVal(1)} curVal={curVal}/>
            <Star name={name} value={2} onClick={()=>setCurVal(2)} curVal={curVal}/>
            <Star name={name} value={3} onClick={()=>setCurVal(3)} curVal={curVal}/>
            <Star name={name} value={4} onClick={()=>setCurVal(4)} curVal={curVal}/>
            <Star name={name} value={5} onClick={()=>setCurVal(5)} curVal={curVal}/>
        </div>
    )
}