import React from 'react'
export default function Star (props) {
    const {name,value,curVal,onClick} = props
    const id = `${name}_${value}`
    return(
        <React.Fragment>
            <label htmlFor={id} style={{cursor: 'pointer',height: 30}} onClick={()=>onClick()}>
                    <svg width="30" height="28" viewBox="0 0 30 28" fill={curVal>=value?'var(--dr2)':'none'}  stroke="var(--dr2)" strokeWidth="1" xmlns="http://www.w3.org/2000/svg">
                        <path d="M15 0L18 10H29L20.5 17L24 27L15 21L6 27L9.5 17L1 10H12L15 0Z"/>
                    </svg>
            </label>
            <input type="radio" id={id} name={name} value={value}/>
            <style jsx>{`
                label svg{transition: 500ms ease;}
                label svg:hover{stroke:white;}
                input[type="radio"] {display: none;}
            `}</style>
        </React.Fragment>
    )
}