import {Link} from "react-router-dom"

export default function HeaderLink(props) {
    const {pageName,linkName,link,mobile,setIsMobNavOpen} = props
    return(
        <li className="HeaderLink" style={pageName === linkName ?{color: 'var(--lt2)',textDecoration: 'overline'}:{}} 
        onClick={()=>{if(mobile){setIsMobNavOpen(false)}}}>
            <Link to={link}>
                {linkName}
            </Link>
        </li>
    )
}