import useTitle from "../components/useTitle"

export default function RatesAndInsurance(props) {
  const {setPageName} = props
  setPageName("Rates and Insurance")
  useTitle("Rates and Insurance")
  return (
    <section id="content">
        <div id="rates">
            <h1>Rates</h1>
            <div><p>Initial Assessment</p><p className="price">$200.00</p></div>
            <hr/>
            <div><p>Follow up session (45-60 minutes)</p><p className="price">$125.00</p></div>
            <hr/>
            <div><p>Follow up session (16-30 minutes)</p><p className="price">$65.00</p></div>
        </div>
        <div id="insurance">
            <h1>Insurance</h1>
            <p>I accept NC Medicaid, NC Healthchoice, Medicare, Humana, United Healthcare, Blue Cross/Blue Shield, Cigna, Tricare, and Magellon.</p>
            <p>My billing manager will bill your insurance.  Please bring your insurance card to your initial appointment.</p>
            <p>Copays and deductibles are due at the time of service.</p>
        </div>
        <style jsx>{`
          #content{
            display: flex;
            justify-content: space-evenly;
            padding:20px;
            color:var(--dr2);
            flex-wrap: wrap;
          }
          #content>div{
              margin: 0 20px;
              max-width: 450px;
          }
          #content h1{
              font-size: 28px;
              font-weight: 600;
              text-align: center;
              color:var(--dr1);
              border-bottom: solid 1.5px var(--dr1);
              padding-bottom: 10px;
          }
          #rates div{
              display:grid;
              grid-template-columns: auto 80px;
          }
          .price{
              text-align: end;
              font-weight: 600;
          }
          #insurance p{text-align: justify;}
        `}</style>
    </section>
  )
}
