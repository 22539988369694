import Header from './components/header.js'
import MobileNav from './components/MobileNav'
import Footer from './components/Footer'
import React, {useState} from 'react'
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom"
import Home from './pages/home'
import Contact from './pages/contact'
import RatesAndInsurance from './pages/ratesAndInsurance'
import Rating from './pages/rating'

function App() {
  const [pageName,setPageName] = useState("Home")
  const [isMobNavOpen,setIsMobNavOpen] = useState(false)
  return (
    <Router>
      <MobileNav pageName={pageName} isMobNavOpen={isMobNavOpen} setIsMobNavOpen={setIsMobNavOpen}/>
      <div id="container" onClick={()=>{
        if(isMobNavOpen){setIsMobNavOpen(false)}
      }}>
        <Header pageName={pageName} isMobNavOpen={isMobNavOpen} setIsMobNavOpen={setIsMobNavOpen}/>
        <Routes>
          <Route exact path="/" element={<Home setPageName={setPageName} />}/>
          <Route path="/contact" element={<Contact setPageName={setPageName}/>}/>
          <Route path="/ratesAndInsurance" element={<RatesAndInsurance setPageName={setPageName}/>}/>
          <Route path="/rating" element={<Rating setPageName={setPageName} />}/>
        </Routes>
        <Footer/>
      </div>
      <style jsx>{`
        #container{
          position: absolute;
          width: 100%;
          left: ${isMobNavOpen?'-250px':0};
          transition: 500ms ease;
        }
      `}</style>
    </Router>
  )
}
export default App;
