import React,{ useState } from 'react'
import Stars from '../components/Stars'
import {Link} from "react-router-dom"
import useTitle from "../components/useTitle"

import {firebaseConfig} from "../components/credentials"
import { initializeApp } from "firebase/app";
import { getFirestore,collection, addDoc } from "firebase/firestore";

initializeApp(firebaseConfig);

const db = getFirestore();

export default function Rating(props) {
    const {setPageName} = props
    setPageName("Rating")
    useTitle("Rating")
    const [result,setResult] = useState(false)
    const [Msg,setMsg] = useState(null)

    const uploadReview = async (review) =>{
      try {
        setMsg("Saving...")
        await addDoc(collection(db, "Reviews"), review).then(()=>{
          if(!result){setResult(true)};
        })
      }catch (e) {
        setMsg("Something whent wrong: ", e);
      }
    }
    const uploadRatings = async (rating) =>{
      try {
        setMsg("Saving...")
        await addDoc(collection(db, "Ratings"), rating).then(()=>{
          if(!result){setResult(true)};
        })
      }catch (e) {
        setMsg("Something whent wrong: ", e);
      }
    }
    //Rating values
    const [Appoint,setAppoint] = useState(null)
    const [Looking,setLooking] = useState(null)
    const [Helpful,setHelpful] = useState(null)
    const [Access,setAccess] = useState(null)
    const [Satisf,setSatisf] = useState(null)
    //Comment
    const [NumOfChars,setNumOfChars] = useState(500)

    const sendReview = async event =>{
        event.preventDefault() // don't redirect the page
        const comment = event.target.comnt.value
        if(Appoint && Looking && Helpful && Access && Satisf && comment){
          uploadRatings({
            Appoint:Appoint,
            Looking:Looking,
            Helpful:Helpful,
            Access:Access,
            Satisf:Satisf,
          })
          uploadReview({Comment:comment})
        }else if(Appoint && Looking && Helpful && Access && Satisf && !comment){
          uploadRatings({
            Appoint:Appoint,
            Looking:Looking,
            Helpful:Helpful,
            Access:Access,
            Satisf:Satisf,
          })
        }else if(!(Appoint && Looking && Helpful && Access && Satisf) && comment){
          uploadReview({
            Comment:comment,
          })
        }else if(Appoint || Looking || Helpful || Access || Satisf){
          setMsg("Rate your experience in all the cattegories!")
        }else{
          setMsg("Nothing to submit!")
        }
    }
    return (
    <section id="content">
      {result ?
      <div className="submitMsg">
        <h1>Submitted successfully!</h1>
        <p>Thank you for your time!</p>
        <Link to="/">
          <button>Return to home page</button>
        </Link>
      </div>
      :
      <React.Fragment>
        <div id="title">
            <h1>Rate your experience with Mark Cadavero, LCSW in the following categories:</h1><hr/>
        </div>
        <form onSubmit={sendReview}>
            <div id="appoint" className="rateDiv">
                <p>Ability to get an appointment in a timely manner</p>
                <Stars name="Appoint" curVal={Appoint} setCurVal={setAppoint}/>
            </div>
            <hr/>
            <div id="looking" className="rateDiv">
                <p>Office was clean and professional looking</p>
                <Stars name="Looking" curVal={Looking} setCurVal={setLooking}/>
            </div>
            <hr/>
            <div id="helpful" className="rateDiv">
                <p> Mark’s interventions were helpful in achieving your treatment goals</p>
                <Stars name="Helpful" curVal={Helpful} setCurVal={setHelpful}/>
            </div>
            <hr/>
            <div id="access" className="rateDiv">
                <p>Mark was easily accessible by phone and returned phone calls in a timely manner</p>
                <Stars name="Access" curVal={Access} setCurVal={setAccess}/>
            </div>
            <hr/>
            <div id="satisf" className="rateDiv">
                <p>Overall satisfaction level with your experience</p>
                <Stars name="Satisf" curVal={Satisf} setCurVal={setSatisf}/>
            </div>
            <hr/>
            <div id="comnt">
                <label htmlFor="comnt">Write a Review</label>
                <textarea name="comnt" maxLength="500" 
                onInput={input=>{
                    const text = input.target.value
                    const length = text.length
                    setNumOfChars(500 - length)
                }}></textarea>
                <p>{NumOfChars} characters left</p>
            </div>
            <hr/>
            <div id="bttn">
              {Msg&&<p style={{marginRight:15,fontWeight:'bold'}}>{Msg}</p>}
              <button type="submit" name="submit">Submit</button>
            </div>
        </form>
      </React.Fragment>
      }
      <style jsx>{`
          #content {
              padding: 20px;
              display: flex;
              flex-direction: column;
              align-items: center;
            }
            #title hr {
              border-color: var(--dr1);
              border-width: 0 0 2px;
            }
            #title h1 {
              text-align: center;
            }
            form p {
              color: var(--dr1);
            }
            h2 {
              padding: 10px;
              text-align: center;
              font-size: 20px;
              font-weight: bold;
              line-height: 1.2em;
              color: var(--dr1);
            }
            
            #comnt textarea {
              padding: 5px;
              border-radius: 5px;
              border: none;
              color: white;
              background-color: var(--md);
              flex: 1 1 auto;
              box-shadow: inset 2px 2px var(--dr2);
              transition: 500ms ease;
            }
            #comnt textarea:focus {
              box-shadow: inset 0 0 var(--dr2);
            }
            
            #comnt {
              display: flex;
              flex-direction: column;
            }
            #comnt label {
              margin-bottom: 5px;
              text-align: center;
            }
            #comnt textarea {
              height: 6.8em;
              line-height: 1.2em;
            }
            
            .rateDiv {
              display: grid;
              align-items: center;
              grid-template-columns: auto 150px;
            }
            .rateDiv > p {
              margin-right: 10px;
            }
            
            #bttn {
              display: flex;
              justify-content: flex-end;
            }
            
            .submitMsg {
              display: flex;
              flex-direction: column;
              align-items: center;
              height: 50vh;
              justify-content: center;
            }
            .submitMsg button {
              margin-top: 20px;
            }
            
            /* Phone */
            @media screen and (max-width: 600px) {
              .rateDiv {
                grid-template-columns: auto;
                place-items: center;
              }
              .rateDiv p {
                text-align: center;
              }
            }
      `}</style>
    </section>
  )
}
